@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

html {
    background: rgb(247, 249, 252);
}

body {
    margin: 0;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}
